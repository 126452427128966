<template>
    
    <div>

        <b-row class="mt-2">
            <b-col xs="6">
                <b-button
                    block
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    @click="viewWorkers()"
                    variant="outline-primary"
                    >
                    VER PLANILLA
                </b-button>
            </b-col>
            <b-col xs="6">
                <ButtonUpload 
                    :code="codeArr[14]" 
                    :isFromPlanilla="true" 
                    :isFromAdmin="true"
                />
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12">
                <b-card class="card-penalty-amounts mt-2" header-tag="header" border-variant="primary" header-bg-variant="primary">
                    <template #header>
                        <h5 class="mb-0 text-white">MONTO DE LA PENALIDAD</h5>
                    </template>
                    <b-card-text class="pt-2">
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="infringement"><strong>INFRACCIÓN:</strong></label>
                                <v-select 
                                    id="infringement"
                                    v-model="infringement"
					                :options="infringementOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="recidivism"><strong>REINCIDENCIA:</strong></label>
                                <v-select 
                                    id="recidivism"
                                    v-model="recidivism"
					                :options="recidivismOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="impact"><strong>IMPACTO:</strong></label>
                                <v-select 
                                    id="impact"
                                    v-model="impact"
					                :options="impactOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="company_size"><strong>TAMAÑO EMPRESA, SEGÚN N° DE TRABAJADORES:</strong></label>
                                <v-select 
                                    id="company_size"
                                    v-model="company_size"
					                :options="companySizeOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="monthly_valuation"><strong>VALORIZACIÓN MENSUAL:</strong></label>
                                <b-form-input id="monthly_valuation" v-model="monthly_valuation" disabled readonly></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" md="6">
                                <label for="doc_ref"><strong>DOC. REF.:</strong></label>
                                <b-form-input id="doc_ref" v-model="doc_ref" disabled readonly></b-form-input>
                            </b-col>
                            <b-col xs="12" md="6">
                                <label for="clause"><strong>CLAUSULA:</strong></label>
                                <b-form-input id="clause" v-model="clause" disabled readonly></b-form-input>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12">
                <b-card class="card-job-criticality" header-tag="header" border-variant="primary" header-bg-variant="primary">
                    <template #header>
                        <h5 class="mb-0 text-white">CRITICIDAD LABORAL</h5>
                    </template>
                    <b-card-text class="pt-2">
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="claims"><strong>RECLAMOS:</strong></label>
                                <v-select 
                                    id="claims"
                                    v-model="claims"
					                :options="claimOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="paralyzes"><strong>PARALIZACIÓN CORE BUSS.:</strong></label>
                                <v-select 
                                    id="paralyzes"
                                    v-model="paralyzes"
					                :options="paralyzeOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="penalties"><strong>PENALIDADES:</strong></label>
                                <v-select 
                                    id="penalties"
                                    v-model="penalties"
					                :options="penaltyOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="laborDebt"><strong>DEUDA LABORAL:</strong></label>
                                <v-select 
                                    id="laborDebt"
                                    v-model="laborDebt"
					                :options="laborDebtOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="labor"><strong>LABORAL:</strong></label>
                                <v-select 
                                    id="labor"
                                    v-model="labor"
					                :options="laborOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="pensionDebt"><strong>DEUDA PROV.:</strong></label>
                                <v-select 
                                    id="pensionDebt"
                                    v-model="pensionDebt"
					                :options="pensionDebtOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="4">
                                <label for="procLab"><strong>PROC. LAB.:</strong></label>
                                <v-select 
                                    id="procLab"
                                    v-model="procLab"
					                :options="procLabOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label for="months"><strong>MESES:</strong></label>
                                <v-select 
                                    id="months"
                                    v-model="months"
					                :options="monthOptions"
                                    disabled 
                                    readonly />
                            </b-col>
                            <b-col xs="12" md="4">
                                <label><strong>SUSTENTO:</strong></label>
                                <ButtonUpload :code="codeArr[15]" :acceptedAllFiles='true' :isFromAdmin="true" /> 
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_claims"><strong>COMENTARIO RECLAMOS:</strong></label>
                                <b-form-textarea
                                    id="comment_claims"
                                    v-model="comment_claims"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_paralyzes"><strong>COMENTARIO PARALIZACIÓN CORE BUSS.:</strong></label>
                                <b-form-textarea
                                    id="comment_paralyzes"
                                    v-model="comment_paralyzes"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_penalties"><strong>COMENTARIO PENALIDADES:</strong></label>
                                <b-form-textarea
                                    id="comment_penalties"
                                    v-model="comment_penalties"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_laborDebt"><strong>COMENTARIO DEUDA LABORAL:</strong></label>
                                <b-form-textarea
                                    id="comment_laborDebt"
                                    v-model="comment_laborDebt"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_labor"><strong>COMENTARIO LABORAL:</strong></label>
                                <b-form-textarea
                                    id="comment_labor"
                                    v-model="comment_labor"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_pensionDebt"><strong>COMENTARIO DEUDA PROV.:</strong></label>
                                <b-form-textarea
                                    id="comment_pensionDebt"
                                    v-model="comment_pensionDebt"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_procLab"><strong>COMENTARIO PROC. LAB.:</strong></label>
                                <b-form-textarea
                                    id="comment_procLab"
                                    v-model="comment_procLab"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col xs="12" md="12">
                                <label for="comment_months"><strong>COMENTARIO MESES:</strong></label>
                                <b-form-textarea
                                    id="comment_months"
                                    v-model="comment_months"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12" md="12">
                                <label for="recommendations"><strong>RECOMENDACIONES:</strong></label>
                                <b-form-textarea
                                    id="recommendations"
                                    v-model="recommendations"
                                    rows="2"
                                    disabled 
                                    readonly
                                ></b-form-textarea>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12">
                <b-card class="card-extra-fields" header-tag="header" border-variant="primary" header-bg-variant="primary">
                    <template #header>
                        <h5 class="mb-0 text-white">VALORES ADICIONALES</h5>
                    </template>
                    <b-card-text class="pt-2">
                        <b-row>
                            <b-col xs="12" md="6">
                                <label for="contract_object"><strong>OBJETO DE CONTRATO:</strong></label>
                                <b-form-input id="contract_object" v-model="contract_object" disabled readonly></b-form-input>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row v-if="earrings.length">
            <b-col cols="12">
                <b-alert show variant="primary" class="mb-2">
                    <h4 class="alert-heading">
                        <b-row align-v="center" align-h="between">
                            <b-col>LISTADO DE PENDIENTES DEL FORMULARIO ({{ previousFormDate }})</b-col>
                            <b-col cols="auto">
                                <ButtonUpload
                                    :code="codeArr[16]"                                    
                                    extra-class="d-inline-block"
                                />
                                <b-button
                                    @click="viewObservations"
                                    variant="outline-info"
                                    class="ml-1"
                                >
                                    <feather-icon size="1x" icon="EyeIcon" />  
                                    Ver Observaciones
                                </b-button>
                            </b-col>
                        </b-row>
                    </h4>
                    <div class="alert-body">

                        <div v-for="(item, index) in earrings" :key="index">
                            <span><strong>Pregunta ({{ item.number }})</strong>: {{ item.question }}</span> <br>
                            <span><strong>Observaciones</strong>:</span>
                            <div style="white-space: pre-line;" v-html="item.comments"></div>
                            
                            <hr v-if="(index + 1) !== earrings.length">
                        </div>

                    </div>
                </b-alert>
            </b-col>
        </b-row>
        
        <b-form>        
            <table class="content-table table table-bordered text-center w-100 ">
                <thead>
                    <tr>
                        <th colspan="12">CUMPLIMIENTO MENSUAL DE LAS OBLIGACIONES LABORALES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><label>1</label></td>
                        <td colspan="3">
                            <label class="form_question">Pago Oportuno de las Remuneraciones</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__1__yes" v-model="questionsArr[0]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__1__yes">SÍ</label> 
                             </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__1__parcial" v-model="questionsArr[0]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__1__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__1__not" v-model="questionsArr[0]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__1__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__1__na" v-model="questionsArr[0]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__1__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[0]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>2</label></td>
                        <td colspan="3">
                            <label class="form_question">Descanso Anual Remunerado</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__2__yes" v-model="questionsArr[1]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__2__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__2__parcial" v-model="questionsArr[1]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__2__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__2__not" v-model="questionsArr[1]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__2__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__2__na" v-model="questionsArr[1]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__2__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[1]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>3</label></td>
                        <td colspan="3">
                            <label class="form_question">Sistema Nacional Pensiones (SNP)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__3__yes" v-model="questionsArr[2]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__3__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__3__parcial" v-model="questionsArr[2]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__3__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__3__not" v-model="questionsArr[2]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__3__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__3__na" v-model="questionsArr[2]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__3__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[2]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>4</label></td>
                        <td colspan="3">
                            <label class="form_question">Es Salud</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__4__yes" v-model="questionsArr[3]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__4__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__4__parcial" v-model="questionsArr[3]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__4__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__4__not" v-model="questionsArr[3]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__4__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__4__na" v-model="questionsArr[3]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__4__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[3]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>5</label></td>
                        <td colspan="3">
                            <label class="form_question">Fcjmms</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__5__yes" v-model="questionsArr[4]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__5__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__5__parcial" v-model="questionsArr[4]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__5__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__5__not" v-model="questionsArr[4]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__5__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__5__na" v-model="questionsArr[4]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__5__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[4]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>6</label></td>
                        <td colspan="3">
                            <label class="form_question">5ta Categoría</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__6__yes" v-model="questionsArr[5]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__6__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__6__parcial" v-model="questionsArr[5]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__6__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__6__not" v-model="questionsArr[5]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__6__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__6__na" v-model="questionsArr[5]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__6__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[5]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>7</label></td>
                        <td colspan="3">
                            <label class="form_question">Sistema Privado de Pensiones (AFP)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__7__yes" v-model="questionsArr[6]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__7__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__7__parcial" v-model="questionsArr[6]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__7__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__7__not" v-model="questionsArr[6]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__7__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__7__na" v-model="questionsArr[6]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__7__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[6]"/>   
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>8</label></td>
                        <td colspan="3">
                            <label class="form_question">Seguro SCTR (Salud y Pensión)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__8__yes" v-model="questionsArr[7]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__8__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__8__parcial" v-model="questionsArr[7]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__8__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__8__not" v-model="questionsArr[7]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__8__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__8__na" v-model="questionsArr[7]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__8__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[7]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>9</label></td>
                        <td colspan="3">
                            <label class="form_question">Seguro Vida Ley (Empleados y Obreros)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__9__yes" v-model="questionsArr[8]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__9__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__9__parcial" v-model="questionsArr[8]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__9__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__9__not" v-model="questionsArr[8]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__9__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__9__na" v-model="questionsArr[8]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__9__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[8]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>10</label></td>
                        <td colspan="3">
                            <label class="form_question">Liquidación de Beneficios Sociales</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__10__yes" v-model="questionsArr[9]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__10__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__10__parcial" v-model="questionsArr[9]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__10__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__10__not" v-model="questionsArr[9]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__10__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__10__na" v-model="questionsArr[9]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__10__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[9]"/>   
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>11</label></td>
                        <td colspan="3">
                            <label class="form_question">Examen Médico Ocupacional de Salida (EMOS)</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__11__yes" v-model="questionsArr[10]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__11__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__11__parcial" v-model="questionsArr[10]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__11__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__11__not" v-model="questionsArr[10]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__11__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__11__na" v-model="questionsArr[10]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__11__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[10]"/> 
                            </div>                        
                        </td>
                        <td>
                            <div>
                                <ButtonComment :comment="medisaludComment" :medisalud="true" :onlyview="true" :question="codeArr[10] "/>
                            </div>   
                        </td>
                    </tr>
                    <tr>
                        <td><label>12</label></td>
                        <td colspan="3">
                            <label class="form_question">Compensación de Tiempo de Servicios</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__12__yes" v-model="questionsArr[11]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__12__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__12__parcial" v-model="questionsArr[11]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__12__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__12__not" v-model="questionsArr[11]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__12__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__12__na" v-model="questionsArr[11]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__12__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[11]"/>  
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>13</label></td>
                        <td colspan="3">
                            <label class="form_question">Gratificaciones Ordinarias</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__13__yes" v-model="questionsArr[12]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__13__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__13__parcial" v-model="questionsArr[12]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__13__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__13__not" v-model="questionsArr[12]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__13__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__13__na" v-model="questionsArr[12]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__13__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[12]"/> 
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><label>14</label></td>
                        <td colspan="3">
                            <label class="form_question">Participación en las Utilidades</label>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__14__yes" v-model="questionsArr[13]" class="custom-control-input" value="1" disabled>
                                <label class="custom-control-label" for="question__14__yes">SÍ</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__14__parcial" v-model="questionsArr[13]" class="custom-control-input" value="2" disabled>
                                <label class="custom-control-label" for="question__14__parcial">PARCIAL</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__14__not" v-model="questionsArr[13]" class="custom-control-input" value="3" disabled>
                                <label class="custom-control-label" for="question__14__not">NO</label>
                            </div>
                        </td>
                        <td>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="question__14__na" v-model="questionsArr[13]" class="custom-control-input" value="4" disabled>
                                <label class="custom-control-label" for="question__14__na">N.A.</label>
                            </div>
                        </td>
                        <td colspan="3">
                            <div>
                                <ButtonUpload :code="codeArr[13]"/>   
                            </div>                        
                        </td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>

            <b-modal
                ref="modal-multi-1"
                size="xl"
                centered
                title-tag="div"
                header-bg-variant="primary"
                header-text-variant="white"
                title="PLANILLA DE EMPLEADOS CARGADA"
                no-close-on-backdrop
                ok-only
                ok-title="ACEPTAR"
            >
                <b-card-text class="my-2">
                    
                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect"
                                v-model="sortBy2"
                                :options="sortOptions"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc2"
                                size="sm"
                                :disabled="!sortBy2"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0 mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput"
                                v-model="filter"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="filter = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <b-row align-v="center" align-h="between">

                        <b-col
                            md="2"
                            sm="4"
                            class="my-1"
                        >
                            <b-form-group
                                class="mb-0"
                            >
                                <label class="d-inline-block text-sm-left mr-50">Por página</label>
                                <b-form-select
                                    id="perPageSelect"
                                    v-model="perPage2"
                                    size="sm"
                                    :options="pageOptions2"
                                    class="w-50"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col
                            cols="2"
                            class="my-1 text-right"
                        >
                            <json-excel :data="downloadWorkers()" name="planilla.xls"></json-excel>
                        </b-col>

                    </b-row>

                    <b-table
                        ref="workersTable"
                        :sticky-header="stickyHeader"
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :per-page="perPage2"
                        :current-page="currentPage2"
                        :items="workers"
                        :fields="fields"
                        :sort-by.sync="sortBy2"
                        :sort-desc.sync="sortDesc2"
                        :sort-direction="sortDirection2"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                    >
                        <template #cell(id)="data">
                            <span class="text-nowrap">
                                {{ data.index+1 }}
                            </span>
                        </template>

                        <template #cell(worker_state)="data">
                            <span class="text-nowrap" v-if="data.item.worker_state">
                                <b-badge
                                    :id="`state-row-${data.item.id}`" 
                                    :variant="`light-${ data.item.worker_state.color}`"
                                >
                                    {{ data.item.worker_state.name }}
                                </b-badge>
                                <b-tooltip
                                    v-if="data.item.worker_state.name === 'CESADO'"
                                    :id="`tooltip-row-${data.item.id}`"
                                    :target="`state-row-${data.item.id}`"
                                    placement="left"
                                >
                                    <p class="mb-0">
                                        Motivo
                                    </p>
                                    <p class="mb-0">
                                        {{ data.item.termination_reason.name }}
                                    </p>
                                </b-tooltip>
                            </span>
                        </template>

                        <template #cell(tariff_compliance)="data">
                            <span class="text-nowrap">
                                <b-button
                                    :id="`tariff-row-${data.item.id}`"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    :variant="`flat-${data.item.tariff_compliance == 1 ? 'success' : 'warning'}`"
                                    class="btn-icon rounded-circle"
                                >
                                    <feather-icon 
                                        size="1.5x"
                                        :icon="data.item.tariff_compliance == 1 ? 'CheckIcon': 'InfoIcon'"                                
                                    /> 
                                </b-button>
                                <b-tooltip
                                    :id="`tooltip-tariff-row-${data.item.id}`"
                                    :target="`tariff-row-${data.item.id}`"
                                    placement="left"
                                >
                                    <p class="mb-0">
                                        {{ data.item.tariff_compliance == 1 ? 'CUMPLE': 'ADJUNTAR BOLETA' }}
                                    </p>
                                </b-tooltip>
                            </span>
                        </template>

                        <template #cell(actions)="data">
                            <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL">

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item @click="editWorker(data.item, data.index)">
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50">Editar</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="deleteWorker(data.item.id)">
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">Eliminar</span>
                                </b-dropdown-item>
                            </b-dropdown>                
                        </template> 
                    </b-table>

                    <!-- pagination -->
                    <div>
                        <b-pagination
                            v-model="currentPage2"
                            :total-rows="totalRows"
                            :per-page="perPage2"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </div>

                </b-card-text>
            </b-modal>

            <b-modal
                ref="my-modal2"
                size="lg"
                ok-title="ACEPTAR"
                cancel-title="CANCELAR"
                cancel-variant="outline-secondary"
                centered
                title-tag="div"
                header-bg-variant="primary"
                header-text-variant="white"
                title="OBSERVACIONES"
                no-close-on-backdrop
            >
                
                <b-form-group class="mt-1">
                    <b-form-checkbox
                        disabled
                        readonly
                        v-for="observation in observations"
                        :key="observation.id"
                        v-model="selectedObservations"
                        :value="observation.id"
                        name="flavour-3a"
                    >
                    {{ observation.text }}
                    </b-form-checkbox>
                </b-form-group>

            </b-modal>
            
        </b-form>
    </div>
    
</template>

<script>

    import ButtonComment from '@core/components/button-comment/ButtonComment.vue'
    import ButtonUpload from '@core/components/button-upload/ButtonUpload.vue';
    import JsonExcel from '../../reporterias/download-excel';
    import { VBTooltip } from 'bootstrap-vue';
    import Ripple from 'vue-ripple-directive';
    import vSelect from 'vue-select';
    import router from '@/router';
    import axios from '@axios';

    export default {
        components: {
            ButtonComment,
            ButtonUpload,
            JsonExcel,
            vSelect
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
        },
        data(){
            return {
                medisaludComment: '',
                questionsArr: [],            
                codeArr: [],
                formId:  router.currentRoute.params.id,       
                workers: [],

                perPage2: 10,
                pageOptions2: [5, 10, 15, 20],
                totalRows: 1,
                currentPage2: 1,
                sortBy2: '',
                sortDesc2: false,
                sortDirection2: 'asc',
                filter: null,   
                filterOn: [],
                fields: [],
                stickyHeader: true,

                earrings: [],
                observations: [],
                previousFormId: null,
                previousFormDate: null,
                selectedObservations: [],

                infringement: null,
                recidivism: null,
                impact: null,
                company_size: null,
                monthly_valuation: null,
                doc_ref: null,
                contract_object: null,
                clause: null,

                claims: null,
                paralyzes: null,
                penalties: null,
                laborDebt: null,
                labor: null,
                pensionDebt: null,
                procLab: null,
                months: null,

                comment_claims: null,
                comment_paralyzes: null,
                comment_penalties: null,
                comment_laborDebt: null,
                comment_labor: null,
                comment_pensionDebt: null,
                comment_procLab: null,
                comment_months: null,
                
                recommendations: null,

                infringementOptions: [
                    { value: '1', label: 'UNA' },
                    { value: '1.5', label: 'DE 2 HASTA 3' },
                    { value: '2', label: 'MÁS DE 3' }
                ],

                recidivismOptions: [
                    { value: '0', label: '1RA VEZ' },
                    { value: '1', label: '2DA VEZ' },
                    { value: '1.5', label: '3RA VEZ O MÁS' }
                ],

                impactOptions: [
                    { value: '0.25', label: 'LEVE' },
                    { value: '2', label: 'MODERADA' },
                    { value: '4', label: 'GRAVE' }
                ],

                companySizeOptions: [
                    { value: '1', label: 'HASTA 100' },
                    { value: '1.5', label: 'DE 101 HASTA 200' },
                    { value: '2', label: 'MÁS DE 201' }
                ],

                claimOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 3' },
                    { value: '3', label: '> 3' }
                ],

                paralyzeOptions: [
                    { value: '1', label: 'SOLO EL PROCESO' },
                    { value: '2', label: 'INDIRECTAMENTE' },
                    { value: '3', label: 'DIRECTAMENTE' }
                ],

                penaltyOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 3' },
                    { value: '3', label: '> 3' }
                ],

                laborDebtOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 500000' },
                    { value: '3', label: '> 500001' }
                ],

                laborOptions: [
                    { value: '3', label: '< 90%' },
                    { value: '2', label: '90% A 95%' },
                    { value: '1', label: '> 95%' }
                ],

                pensionDebtOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 200000' },
                    { value: '3', label: '> 200001' }
                ],

                procLabOptions: [
                    { value: '1', label: '-' },
                    { value: '2', label: '1 A 3' },
                    { value: '3', label: '> 3' }
                ],

                monthOptions: [
                    { value: '1', label: '< 6' },
                    { value: '2', label: '6 A 12' },
                    { value: '3', label: '> 12' }
                ],

                positionsData: [],
                optionsPositons: [],
                tariff_id: null
            }
        },
        computed: {
            sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
            },
        },
        async beforeMount(){
            this.getInformation();
            await this.getFormInformation();
            this.getMedisaludComment();
            this.getPenaltyAmounts();
            this.getJobCriticality();
            await this.getFileQuestion();
        },
        methods: {
            getMedisaludComment () {
                axios.get(`/medisalud/form/${router.currentRoute.params.id}/comment`)
                .then(response => this.medisaludComment = response.data.comment)
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            async getPositions () {
                this.optionsPositons = [];
                this.positionsData = [];
                
                if (this.tariff_id) {
                    const result = await axios.get(`/positions/select?tariff_id=${this.tariff_id}`)
                    if (result && result.data) {
                        this.positionsData = result.data
                        Array.from(new Set([...result.data.map( item => item.name)])).map( position => {
                            this.optionsPositons.push(position)
                        })   
                    }
                }
            }, 
            async viewWorkers (){

                let response = await axios.get(`/form/${this.formId}/workers`);
                this.workers = response.data;

                if (this.tariff_id) {
                    this.workers = []
                    
                    response.data.map( item => {                                
                        let position = this.optionsPositons.find( position => position == item.role)
                        
                        this.workers.push({
                            id: item.id,
                            fullName: item.fullName,
                            dni: item.dni,
                            role: position || null,
                            category: item.category,
                            salary: item.salary ? Number(item.salary).toFixed(2) : null,
                            daily_wage: item.daily_wage ? Number(item.daily_wage).toFixed(2) : null,
                            tariff_compliance: item.tariff_compliance,
                            phone: item.phone,                            
                            email: item.email,
                            worker_state: item.worker_state,
                            termination_reason: item.termination_reason
                        })
                    })
                }                

                this.totalRows = this.workers.length;

                this.currentPage2 = 1;
                this.sortBy2 = '';
                this.sortDesc2 = false;
                this.sortDirection2 = 'asc';
                this.filter = null;
                
                const styleColum = {
                        thClass: 'text-center',
                        tdClass: 'text-center'
                    }
                this.fields = [
                    {
                        key: 'id', 
                        label: '#',
                        sortable: true,
                        tdClass: styleColum.tdClass
                    },
                    {
                        key: 'fullName', 
                        label: 'Nombre completo',
                        sortable: true,
                        thClass: styleColum.thClass
                    },
                    {
                        key: 'dni', 
                        label: 'DNI',
                        sortable: true,
                        ...styleColum
                    },
                    {
                        key: 'role', 
                        label: 'Puesto/Cargo/Rol',
                        sortable: true,
                        ...styleColum
                    },
                    {
                        key: 'category', 
                        label: 'Categoría',
                        sortable: true,
                        ...styleColum
                    },
                    {
                        key: 'salary', 
                        label: 'Sueldo',
                        sortable: true,
                        thClass: styleColum.thClass,
                        tdClass: 'text-right'
                    },
                    {
                        key: 'daily_wage', 
                        label: 'Jornal Diario',
                        sortable: true,
                        thClass: styleColum.thClass,
                        tdClass: 'text-right'
                    },
                    {
                        key: 'phone', 
                        label: 'Celular',
                        sortable: true,
                        thClass: styleColum.thClass,
                    },
                    {
                        key: 'email', 
                        label: 'Correo electrónico',
                        sortable: true,
                        thClass: styleColum.thClass,
                    }, 
                    {
                        key: 'worker_state', 
                        label: 'Estado',
                        sortable: true,
                        ...styleColum
                    }
                ];

                if (this.tariff_id) {
                        const tariff_col = {
                            key: 'tariff_compliance', 
                            label: 'Análisis Tarifario',
                            sortable: false,
                            ...styleColum
                        }
                        this.fields.splice(this.fields.length - 1, 0, tariff_col);
                    }

                this.$refs['modal-multi-1'].show();
            },
            removeCurrencyFormat (item, currency = 'PEN') {
                if (item) {
                    if (currency === 'PEN') item = item.replace('S/ ', '');

                    if (currency === 'USD') item = item.replace('$ ', '');

                    item = item.replaceAll(',', '');

                    return item
                }

                return '-'
            },
            downloadWorkers () {
                let array = [];

                for (let a = 0; a < this.workers.length; a++)
                {
                    let objBody = {
                        'ITEM': (a + 1),
                        'NOMBRE COMPLETO': this.workers[a]['fullName'],
                        'DNI': this.workers[a]['dni'],
                        'PUESTO/CARGO/ROL': this.workers[a]['role'],
                        'CATEGORÍA': this.workers[a]['category'],
                        'SUELDO': this.removeCurrencyFormat(this.workers[a]['salary']),
                        'JORNAL DIARIO': this.removeCurrencyFormat(this.workers[a]['daily_wage']),
                        'CELULAR': this.workers[a]['phone'],
                        'CORREO ELECTRÓNICO': this.workers[a]['email'],
                        'ESTADO': this.workers[a]['worker_state'] ? this.workers[a]['worker_state']['name'] : null,
                        'MOTIVO CESE': this.workers[a]['termination_reason'] ? this.workers[a]['termination_reason']['name'] : null
                    };

                    array.push(objBody);
                }

                return array;
            },
            async viewObservations (){
                let response = await axios.get(`/form/${this.previousFormId}/observations`);
                this.observations = response.data;

                this.selectedObservations = [];

                this.observations.map(item => {
                    if (item.check){
                        this.selectedObservations.push(item.id);
                    }
                });

                this.$refs['my-modal2'].show();
            },
            async getPenaltyAmounts (){
                let response = await axios.get(`/view-form/${this.formId}/data-penalty-amounts`);

                if (response.data.infringement !== null){
                    this.infringement = this.infringementOptions.find(i => i.value == response.data.infringement)
                }

                if (response.data.recidivism !== null){
                    this.recidivism = this.recidivismOptions.find(i => i.value == response.data.recidivism)
                }

                if (response.data.impact !== null){
                    this.impact = this.impactOptions.find(i => i.value == response.data.impact)
                }

                if (response.data.company_size !== null){
                    this.company_size = this.companySizeOptions.find(i => i.value == response.data.company_size)
                }

                this.monthly_valuation = response.data.monthly_valuation;
                this.doc_ref = response.data.doc_ref;
                this.contract_object = response.data.contract_object
                this.clause = response.data.clause;
            },
            async getJobCriticality (){
                let response = await axios.get(`/view-form/${this.formId}/data-job-criticality`)

                if (response.data.claims !== null){
                    this.claims = this.claimOptions.find(i => i.value == response.data.claims)
                }

                if (response.data.paralyzes !== null){
                    this.paralyzes = this.paralyzeOptions.find(i => i.value == response.data.paralyzes)
                }

                if (response.data.penalties !== null){
                    this.penalties = this.penaltyOptions.find(i => i.value == response.data.penalties)
                }

                if (response.data.laborDebt !== null){
                    this.laborDebt = this.laborDebtOptions.find(i => i.value == response.data.laborDebt)
                }

                if (response.data.labor !== null){
                    this.labor = this.laborOptions.find(i => i.value == response.data.labor)
                }

                if (response.data.pensionDebt !== null){
                    this.pensionDebt = this.pensionDebtOptions.find(i => i.value == response.data.pensionDebt)
                }

                if (response.data.procLab !== null){
                    this.procLab = this.procLabOptions.find(i => i.value == response.data.procLab)
                }

                if (response.data.months !== null){
                    this.months = this.monthOptions.find(i => i.value == response.data.months)
                }

                this.comment_claims = response.data.comment_claims
                this.comment_paralyzes = response.data.comment_paralyzes
                this.comment_penalties = response.data.comment_penalties
                this.comment_laborDebt = response.data.comment_laborDebt
                this.comment_labor = response.data.comment_labor
                this.comment_pensionDebt = response.data.comment_pensionDebt
                this.comment_procLab = response.data.comment_procLab
                this.comment_months = response.data.comment_months

                this.recommendations = response.data.recommendations
            },
            async getFormInformation () {
                const { data } = await axios.get(`/provider/form/${this.formId}/edit`);
                
                if (data) 
                {
                    const { earrings, previousFormId, previousFormDate, formData } = data;

                    this.earrings = earrings;
                    this.previousFormId = previousFormId;
                    this.previousFormDate = previousFormDate;
                    this.tariff_id = formData.tariff_id;
                }
                await this.getPositions();
            },
            getInformation(){
                axios.get(`/reviser/form-a/${this.formId}`)
                .then( response => {
                            
                    if(response.data){
                        let cont = 0;                                     
                        for (const key in response.data) { 
                            if(key === 'question__'+(cont+1)){                          
                                this.questionsArr[cont] = response.data[key];                    
                                cont++;   
                            }
                        }                           
                    }                
                })
            },
            async getFileQuestion(){
                const response = await axios.get('/form/questions');
                this.codeArr = response.data;

                if (this.earrings.length)
                {
                    const questions = this.earrings.map(item => `- ${item.question}`);

                    this.codeArr[16].title = `LISTADO DE PENDIENTES DEL FORMULARIO (${this.previousFormDate})`;
                    this.codeArr[16].description = questions.join('<br/>');
                }
            }
        }
    }

</script>

<style scoped>

    .content-table {
        border-collapse: collapse;
        min-width: 400px;
        border-radius: 10px 10px;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    .content-table thead tr th {
        font-weight: bold;
        font-size: 20px;
        padding: 45px 0px;
    }

    .content-table td {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    .form_question{
        font-size: 1rem;
        margin-bottom: 0px;
    }
    
    .custom-control-label{
        font-size: .8rem;
    }
    
     .btn-send {
        outline: none;
        display: block;
        border: solid 1px #d8a400 !important;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        width: 100%;
        border-radius: 35px;
        color: #d8a400 !important;
        background: inherit !important;
        cursor: pointer;
        transition: all 0.3s linear;
        text-transform: uppercase;
        padding-top: 15px;
        padding-bottom: 15px;
        box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.15);
    }
    
    .btn-send:hover,
    .btn-send:focus {
        background: #d8a400 !important;
        color:  rgba(255, 255, 255, 0.842) !important;
    }
    
    .btn-send:active {
        color: #dfbb45;
    }

    .btn-save {        
        outline: none;
        position: fixed;
        width: 55px;
        height: 55px;
        line-height: 55px;        
        bottom: 90px;
        right: 60px;
        color: rgba(255, 255, 255, 0.842);
        background: #d8a400;
        transition: all 0.3s linear;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
        cursor: pointer; 
    }
    
    .btn-save:hover,
    .btn-save:focus {
        color:  rgba(255, 255, 255, 0.842);
    }
    
    .btn-save:active {
        background: #0020d8;
    }
    
    .btn-save .btn-icon{
        position: fixed;
        width: 30px;
        height: 40px;
        bottom: 95px;
        right: 72px;
    }

    .custom-control.custom-checkbox {
        margin-bottom: .75rem;
    }

    .custom-control.custom-checkbox:nth-last-child(1) {
        margin-bottom: 0;
    }
    
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

